<template>
    <div class="container d-flex justify-content-center align-items-center mt-4">
        <form class="elevation-4 px-4 py-4" @submit="handleSubmit">
            <h1>Admin Panel - Primo login</h1>
            <br>
            <div class="row">
                <div class="col">
                    <label>Password</label>
                    <input type="password" v-model="password" class="form-control">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Conferma password</label>
                    <input type="password" v-model="confirm_passwd" class="form-control">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <loading-button title="Applica" icon="fa fa-edit" color="primary" :loading="loading" />
                </div>
            </div>
            <br>
            <b-alert v-if="error !== ''" type="danger" :title="error" />
        </form>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import bAlert from '@/components/bAlert.vue';
import loadingButton from '@/components/loadingButton.vue';

export default {
    name: 'LoginView',
    components: {
        bAlert,
        loadingButton,
    },
    data: () => ({
        confirm_passwd: '',
        password: '',
        error: '',
        loading: false,
    }),
    mounted() {
        if(this.$store.getters.getJWT === '')
            this.$router.push('/login');
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            var self = this;
            this.loading = true;
            var data = {
                passwd: this.password,
                confirmation_passwd: this.confirm_passwd,
            };

            AuthService.changePasswd(data, this.$store.getters.getJWT, this.$store.getters.getCSRF)
            .then(res => {
                console.log(res);
                self.$router.push('/');
            }).catch(err => {
                self.error = err;
                console.log(err);
            });
        }
    }
};
</script>