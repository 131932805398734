<template>
    <div :id="id" class="modal fade">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3>{{ headerTitle }}</h3>
                    <button class="btn-close" @click="$emit('close-modal')" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot name="modal-body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bModal',
    props: {
        headerTitle: String,
        id: String,
    },
    methods: {
    }
};
</script>