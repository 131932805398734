import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue';
import UsersView from '../views/UsersView.vue';
import addUserView from '../views/addUserView.vue';
import DoorsView from '../views/DoorsView.vue';
import addDoorView from '../views/addDoorView.vue';
import editDoorView from '../views/editDoorView.vue';
import firstLoginView from '../views/firstLoginView.vue';
import SettingsView from '../views/SettingsView.vue';
import store from '@/store';
import AuthService from '@/services/AuthService';

Vue.use(VueRouter)

const checkAuth = (to, from, next) => {
  if(to.name !== "login") {
    if(store.getters.isAuth) {
      console.log('isAuth')
      return next();
    } else {
      if(AuthService.loadAuthData()) {
        return next();
      } else {
        return next('/login');
      }
    }
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: checkAuth,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/utenti',
    name: 'view-utenti',
    component: UsersView,
    beforeEnter: checkAuth,
  },
  {
    path: '/aggiungi-utente',
    name: 'add user',
    component: addUserView,
    beforeEnter: checkAuth,
  },
  {
    path: '/porte',
    name: 'doors',
    component: DoorsView,
    beforeEnter: checkAuth,
  },
  {
    path: '/aggiungi-porta',
    name: 'add door',
    component: addDoorView,
    beforeEnter: checkAuth,
  },
  {
    path: '/modifica-porta/:id',
    name: 'edit door',
    component: editDoorView,
    beforeEnter: checkAuth,
  },
  {
    path: '/password-reset',
    name: 'reset password',
    component: firstLoginView,
    beforeEnter: checkAuth,
  },
  {
    path: '/impostazioni',
    name: 'settings',
    component: SettingsView,
    beforeEnter: checkAuth,
  },
]

const router = new VueRouter({
  routes
})

export default router
