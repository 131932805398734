export default {
    state: {
        appName: '',
        appLogo: '',
    },
    mutations: {
        setAppName: (state, appName) => state.appName = appName,
        setAppLogo: (state, appLogo) => state.appLogo = appLogo,
    },
    actions: {
        setAppName: (context, appName) => context.commit('setAppName',appName),
        setAppLogo: (context, appLogo) => context.commit('setAppLogo', appLogo),
    },
    getters: {
        getAppName: (state) => state.appName,
        getAppLogo: (state) => state.appLogo,
    }
};