<template>
    <div class="d-flex justify-content-center">
        <div v-if="color === ''" class="spinner-border text-primary" role="status"></div>
        <div v-else :class="'spinner-border text-'+color" role="status"></div>
    </div>
</template>

<script>
export default {
    name: 'loadingSpinner',
    props: {
        color: String,
    }
};
</script>