import axios from 'axios';

const URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_DEV_API_URL

export default {
    getDoors(jwt) {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/doors/all', {headers: {'Authorization': jwt}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    createDoor(data, jwt, csrf) {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/doors/add', data, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf,
            }}).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    deleteDoor(token, doorId) {
        return new Promise((resolve, reject) => {
            axios.get(URL+`/doors/delete/${doorId}`, {headers: {'Authorization': token}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    getDoorData(jwt, id) {
        return new Promise((resolve, reject) => {
            axios.get(URL+`/doors/get/${id}`, {headers: {'Authorization': jwt}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    editDoor(jwt, csrf, id, data) {
        return new Promise((resolve, reject) => {
            axios.post(URL+`/doors/edit/${id}`, data, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf,
            }}).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    uploadFiles(jwt, id, formData, onProgress) {
        return new Promise((resolve, reject) => {
            axios.post(URL+`/doors/upload/${id}`, formData, {
                headers: {
                    'Authorization': jwt,
                },
                onUploadProgress: onProgress
        }).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    getSpots(jwt, doorId, mime) {
        return new Promise((resolve, reject) => {
            axios.get(URL+"/doors/spots/"+doorId+"?mime="+mime , {headers: {
                "Authorization": jwt,
            }})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    deleteSpot(jwt, spotId) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL}/doors/delete-spot/${spotId}`, {
                headers: {
                    Authorization: jwt,
                }
            }).then(res => resolve(res.data))
            .catch(err => reject(err))
        });
    }
};