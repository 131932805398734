<template>
    <div :class="'alert alert-'+type" role="alert">
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'bAlert',
    props: {
        type: String,
        title: String,
    }
};
</script>