<template>
    <button v-if="!loading" :class="'btn btn-'+color">
        <i v-if="icon" :class="icon"></i>
        {{ title }}
    </button>
    <button v-else :class="'btn btn-'+color">
        <loading-spinner color="white" />
    </button>
</template>

<script>
import loadingSpinner from './loadingSpinner.vue';

export default {
    name: 'loadingButton',
    props: {
        title: String,
        icon: String,
        loading: Boolean,
        color: String,
    },
    data: () => ({
        isLoading: false,
    }),
    components: {
        loadingSpinner,
    },
    watch: {
        loading(val) {
            this.isLoading = val;
        }
    }
}
</script>