<template>
    <div @click="$emit('click')" :class="'badge bg-'+color">
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'bBadge',
    props: {
        color: String,
        title: String,
    }
};
</script>