<template>
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
            <router-link to="/" class="d-block">{{ accountName }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'accountPanel',
    props: {
        accountName: String,
    }
};
</script>