<template>
    <li class="nav-item" @click="$emit('click')">
        <a :class="active ? 'nav-link active' : 'nav-link'">
            <i v-if="icon" :class="icon"></i>
            {{ title }}
        </a>
    </li>
</template>

<script>
export default {
    name: 'bTab',
    props: {
        title: String,
        active: Boolean,
        icon: String,
    }
};
</script>

<style>
.nav-link {
    cursor: pointer;
}
</style>