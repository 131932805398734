<template>
  <div v-if="$store.getters.isAuth" class="wrapper sidebar-mini">
    <main-header />
    <side-bar />
    <div class="content-wrapper px-2 py-4">
      <router-view />
    </div>
  </div>
  <div v-else class="container">
    <router-view />
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap';
import 'admin-lte/dist/js/adminlte';

import mainHeader from '@/components/mainHeader.vue';
import sideBar from '@/components/sideBar.vue';
// import AuthService from './services/AuthService';

export default {
  name: 'App',
  components: {
    mainHeader,
    sideBar,
  },
  mounted() {
    // console.log(process.env.VUE_APP_DEV_API_URL)
  },
};
</script>

<style>
@import url('./style.scss');
</style>
