<template>
    <form @submit="handleSubmit">
        <h5>Aggiungi evento</h5>
        <br>
        <div class="row">
            <div class="col">
                <label>Nome</label>
                <input type="text" v-model="name" class="form-control">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>Url</label>
                <input type="text" v-model="url" class="form-control">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <label>Ora inizio</label>
                <input type="time" v-model="start_time" class="form-control">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <loading-button color="primary" title="Aggiungi" icon="fa fa-plus" :loading="loading" />
            </div>
        </div>
    </form>
</template>

<script>
import loadingButton from '@/components/loadingButton.vue';

export default {
    name: 'addEventForm',
    data: () => ({
        name: '',
        start_time: '',
        end_time: '',
        url: '',
        types: [],
    }),
    components: {
        loadingButton,
    },
    props: {
        loading: Boolean,
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            var data = {
                nome: this.name,
                ora_inizio: this.start_time,
                ora_fine: this.end_time,
                url: this.url,
            };

            this.$emit('form-submit', data);
        }
    },
    mounted() {
        // EMPTY
    }
};
</script>