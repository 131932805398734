<template>
  <div class="container">
    <div class="col">
      <h2>Dashboard</h2>
    </div>
  </div>
</template>

<script>
import SettingsService from '@/services/SettingsService';

export default {
  name: 'HomeView',
  components: {
  },
  data: () => ({
    rooms: [],
    newRoomName: '',
    loading: true,
  }),
  mounted() {
    var self = this;

    SettingsService.loadSettings(this.$store.getters.getJWT)
    .then(res => {
      self.$store.dispatch('setAppName', res.sets.nome_app);
      self.$store.dispatch('setAppLogo', res.sets.logo_app);
    }).catch(err => {
      console.log(err);
    });
  },
  methods: {
    closeModal() {
      document.getElementById('close').click();
    },
  }
}
</script>
