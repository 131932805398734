<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Admin Panel - Utenti</h1>
            </div>
            <div class="col-2">
                <button @click="$router.push('/aggiungi-utente')" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                    Nuovo utente
                </button>
            </div>
        </div>
        <br>
        <loading-spinner v-if="loading" />
        <data-table :headers="headers" v-else>
            <template v-if="users.length > 0" slot="body">
                <tr :key="u.id" v-for="u in users">
                    <td>{{ u.id }}</td>
                    <td>{{ u.email }}</td>
                    <td>{{ u.name }}</td>
                    <td>{{ u.role }}</td>
                    <td>
                        <i class="fa fa-trash" style="color: red;" @click="setToDelete(u.id)"></i>
                    </td>
                </tr>
            </template>
            <template slot="body" v-else>
                <div>
                    <h3 class="text-center">Nessuna porta trovata</h3>
                </div>
            </template>
        </data-table>
        <b-modal @close-modal="closeModal" id="myModal" header-title="Sei sicuro?">
            <template slot="modal-body">
                <div>
                    <div class="row">
                        <div class="col">
                            <button @click="confirmDelete" class="btn btn-success">
                                <i class="fa fa-check"></i>
                                Si
                            </button>
                        </div>
                        <div class="col">
                            <button @click="closeModal" class="btn btn-danger">
                                <i class="fa fa-xmark"></i>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import UserService from '@/services/UserService';
import loadingSpinner from '@/components/loadingSpinner.vue';
import dataTable from '@/components/dataTable.vue';
import bModal from '@/components/bModal.vue';
import $ from 'jquery';

export default {
    name: 'UsersView',
    components: {
        loadingSpinner,
        dataTable,
        bModal,
    },
    data: () => ({
        toDeleteId: '',
        users: [
        ],
        loading: true,
        headers: [
            {text: 'Id'},
            {text: 'Email'},
            {text: 'Username'},
            {text: 'Ruolo'},
            {text: 'Azione'}
        ],
    }),
    mounted() {
        this.loadUsers();
    },
    methods: {
        loadUsers() {
            var self = this;
            UserService.getUsers(this.$store.getters.getJWT)
            .then(res => {
                console.log(res);
                self.loading = false;
                self.users = res.users.data;
            }).catch(err => {
                console.log(err);
                self.loading = false;
            });
        },
        setToDelete(id) {
            this.toDeleteId = id;
            $('#myModal').addClass('show');
            $('#myModal').show();
        },
        confirmDelete() {
            var self = this;
            UserService.deleteUser(this.$store.getters.getJWT, this.toDeleteId)
            .then(res => {
                console.log(res);
                self.loading = true;
                self.closeModal();
                self.loadUsers();
            }).catch(err => console.log(err));
        },
        closeModal() {
            $('#myModal').removeClass('show');
            $('#myModal').hide();
        },
    }
};
</script>