<template>
    <div class="border container">
        <b-modal v-if="selectedEvent" :header-title="'Cancellare '+selectedEvent.nome+' ?'" id="eventModal">
            <template slot="modal-body">
                <div class="row">
                    <p>Ora inizio: {{ selectedEvent.ora_inizio }}</p>
                    <p>Ora fine: {{ selectedEvent.ora_fine }}</p>
                    <p>Url: {{ selectedEvent.url }}</p>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <button data-bs-dismiss="modal" @click="deleteEvent" class="btn btn-success">
                            <i class="fa fa-check"></i>
                            Si
                        </button>
                    </div>
                    <div class="col">
                        <button data-bs-dismiss="modal" class="btn btn-danger">
                            <i class="fa fa-xmark"></i>
                            No
                        </button>
                    </div>
                </div>
            </template>
        </b-modal>
        <div class="d-flex justify-content-around align-items-center">
            <icon-button @click="prevMonth" icon="fa fa-angle-left" />
            <h3>{{ months[month] }} - {{ date.getFullYear() }}</h3>
            <icon-button @click="nextMonth" icon="fa fa-angle-right" />
        </div>
        <br>
        <div class="row">
            <div class="col-2">
                <addEventForm @form-submit="onNewEvent" :loading="loading" />
            </div>
            <div class="col">
                <div class="calendar-monthly">
                    <div @click="selectDay(d)" v-for="d in totDays" :key="d" :class="selectedDay === d ? 'border day selected' : 'border day'">
                        {{ weekDays[new Date(date.getFullYear(), month, d).getDay()] }} - {{ d }}
                        <template v-if="calendarEvents.length > 0">
                            <div class="row" :key="ev.id" v-for="ev in calendarEvents" >
                                <b-badge data-bs-target="#eventModal" data-bs-toggle="modal" @click="onEventClick(ev)" color="warning" :title="ev.nome" v-if="d === new Date(ev.data).getDate() && new Date(ev.data).getMonth() === month" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import addEventForm from './addEventForm.vue';
import DateService from '@/services/DateService';
import EventService from '@/services/EventService';
import moment from 'moment';
import iconButton from '@/components/iconButton.vue';
import bBadge from '@/components/bBadge.vue';
import bModal from '@/components/bModal.vue';

export default {
    name: 'bCalendar',
    components: {
        addEventForm,
        iconButton,
        bBadge,
        bModal,
    },
    props: {
        type: String,
        events: Array,
    },
    watch: {
        events(evs) {
            this.calendarEvents = evs;
        }
    },
    data: () => ({
        weekDays: [
            'Dom',
            'Lun',
            'Mar',
            'Mer',
            'Gio',
            'Ven',
            'Sab'
        ],
        months: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        date: new Date(),
        calendarView: 'month',
        selectedDay: new Date().getDate(),
        month: 0,
        calendarEvents: [],
        totDays: 0,
        days: [],
        loading: false,
        selectedEvent: null,
    }),
    mounted() {
        console.log('today: '+this.selectedDay);
        this.calendarView = this.$props.type;
        this.calendarEvents = this.$props.events;
        this.totDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
        this.month = this.date.getMonth();
        this.loadDays();
        console.log(this.totDays);
    },
    methods: {
        deleteEvent() {
            var self = this;
            EventService.deleteEvent(this.$store.getters.getJWT, this.selectedEvent.id)
            .then(res => {
                console.log(res);
                self.$emit('reload-events');
            }).catch(err => {
                console.log(err);
                alert(err.message);
            });
        },
        onEventClick(e) {
            this.selectedEvent = e;
            console.log('selected event');
            console.log(this.selectedEvent);
        },
        nextMonth() {
            if(this.month < 11) {
                this.month++;
                this.totDays = new Date(this.date.getFullYear(), this.month+1, 0).getDate();
                console.log('totDays: '+this.totDays);
                console.log('month: '+this.month);
                this.loadDays();
            }
        },
        prevMonth() {
            if(this.month > 0) {
                this.month--;
                this.totDays = new Date(this.date.getFullYear(), this.month+1, 0).getDate();
                console.log('totDays: '+this.totDays);
                this.loadDays();
            }
        },
        onNewEvent(data) {
            this.loading = true;
            data.data = DateService.format(new Date(this.date.getFullYear(), this.month, this.selectedDay));
            data.door_id = this.$route.params.id;
            console.log(data);
            var self = this;
            var jwt = this.$store.getters.getJWT;
            var csrf = this.$store.getters.getCSRF;

            EventService.addEvent(jwt, csrf, data)
            .then(res => {
                console.log(res);
                self.$emit('reload-events');
                self.loading = false;
            }).catch(err => {
                self.error = err.message;
                console.log(err);
                self.loading = false;
                alert(err.message);
            });
        },
        dateIsSame(d1, d2) {
            return moment(new Date(DateService.format(d1))).isSame(d2);
        },
        loadEvents() {
            var self = this;
            var id = this.$route.params.id;

            EventService.getEvents(id, this.$store.getters.getJWT)
            .then(res => {
                console.log(res);
                self.calendarEvents = res.events;
            }).catch(err => {
                console.log(err);
                self.error = err.message;
            });
        },
        selectDay(day) {
            this.selectedDay = day;
            console.log('selected: '+this.selectedDay);
            this.$emit('daySelected', this.selectedDay);
        },
        loadDays() {
            this.days = [];
            for(var i in this.totDays) {
                this.days.push({
                    number: i,
                    month: this.months[this.date.getMonth()],
                    weekDay: new Date(this.date.getFullYear(), this.month+1, i).getDay(),
                    year: this.date.getFullYear()
                });
            }
        }
    }
};
</script>

<style>
.calendar-monthly {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(7, 1fr);
}
.week-day {
    font-weight: bold;
    font-size: 24px;
    border: 4px solid rgb(100,100,100);
    text-align: center;
    border-radius: 2px;
}
.day {
    width: auto;
    min-width: 80px;
    height: auto;
    min-height: 100px;
    cursor: pointer;
}
.day.selected {
    background-color: rgb(230,230,230);
}
</style>