<template>
    <ul class="nav nav-pills">
        <slot></slot>
    </ul>
</template>

<script>
export default {
    name: 'tabMenu',
};
</script>