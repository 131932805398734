<template>
    <div class="container">
        <h2>Impostazioni</h2>
        <br>
        <label>Logo app</label>
        <div class="d-flex justify-content-center">
            <img v-if="appLogo !== null || ''" style="width: 200px;height: 200px;" class="img rounded" :src="'/api/download?file='+appLogo" alt="logo app"/>
        </div>
        <label>Carica immagine logo</label>
        <input @change="onFileChange" type="file" id="appLogo" class="form-control">
        <br>
        <form @submit="handleSubmit">
            <div class="row">
                <div class="col">
                    <label>Nome app</label>
                    <input type="text" v-model="appName" class="form-control">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <button type="submit" class="btn btn-primary">
                        <i class="fa fa-edit"></i>
                        Applica modifiche
                    </button>
                </div>
            </div>
            <br>
            <b-alert type="success" title="Dati aggiornati correttamente" v-if="done" />
            <br>
            <b-alert type="danger" :title="errorData" v-if="errorData !== ''" />
        </form>
    </div>
</template>

<script>
import bAlert from '@/components/bAlert.vue';
import SettingsService from '@/services/SettingsService';

export default {
    name: 'SettingsView',
    components: {
        bAlert,
    },
    data: () => ({
        appName: '',
        appLogo: '',
        errorData: '',
        done: false,
    }),
    mounted() {
        this.appName = this.$store.getters.getAppName;
        this.appLogo = this.$store.getters.getAppLogo;
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            var self = this;
            SettingsService.setAppName({
                appname: this.appName,
            }, this.$store.getters.getJWT, this.$store.getters.getCSRF).then(res => {
                console.log(res);
                self.done = true;
                self.loadSettings();
            }).catch(err => {
                console.log(err);
                self.errorData = err.message;
            });
        },
        loadSettings() {
            var self = this;
            SettingsService.loadSettings(this.$store.getters.getJWT)
            .then(res => {
                self.$store.dispatch('setAppName', res.sets.nome_app);
                self.$store.dispatch('setAppLogo', res.sets.logo_app);
                self.appName = res.sets.nome_app;
                self.appLogo = res.sets.logo_app;
            }).catch(err => {
                console.log(err);
                self.errorData = err.message;
            });
        },
        onFileChange() {
            var file = document.getElementById('appLogo').files[0];
            var formData = new FormData();
            formData.append('app-logo', file);

            var self = this;
            SettingsService.uploadLogo(formData, this.$store.getters.getJWT, this.$store.getters.getCSRF)
            .then(res => {
                console.log(res);
                self.loadSettings();
            }).catch(err => {
                alert(err.message);
                console.log(err);
            });
        }
    }
};
</script>