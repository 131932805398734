<template>
    <li class="nav-item">
        <router-link v-if="active" class="nav-link active" :to="url">
            <i :class="'nav-icon '+icon"></i>
            <p>{{ title }}</p>
        </router-link>
        <router-link v-else class="nav-link" :to="url">
            <i :class="'nav-icon '+icon"></i>
            <p>{{ title }}</p>
        </router-link>
    </li>
</template>

<script>
export default {
    name: 'navItem',
    props: {
        active: Boolean,
        url: String,
        title: String,
        icon: String,
    },
};
</script>