export default class DateService {
    static format(date) {
        var myDate = new Date(date);
        return `${myDate.getFullYear()}-${myDate.getMonth()+1}-${myDate.getDate()}`;
    }

    static objToDate(obj) {
        return new Date(obj.year, obj.month, obj.number);
    }

    static dateToObj(date) {
        return {
            number: date.getDate(),
            month: date.getMonth(),
            weekDay: date.getDay(),
            year: date.getFullYear()
        };
    }
}