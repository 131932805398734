<template>
    <div class="container">
        <h2>Aggiungi una porta</h2>
        <br>
        <form @submit="handleSubmit">
            <div class="row">
                <div class="col">
                    <label>Id porta</label>
                    <input type="text" v-model="id" class="form-control">
                </div>
                <div class="col">
                    <label>Nome porta</label>
                    <input type="text" v-model="nome" class="form-control">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Utente</label>
                    <select class="form-select" v-model="utente">
                        <option value="">Seleziona</option>
                        <option :key="usr.id" v-for="usr in users" :value="usr.id">{{ usr.name }}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Porta collegata (facoltativo)</label>
                    <select class="form-select" v-model="linked">
                        <option value="">Seleziona</option>
                        <option :key="door.uid" v-for="door in doors" :value="door.uid">{{ door.nome }}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <button type="submit" class="btn btn-primary">
                        <i class="fa fa-save"></i>
                        Salva
                    </button>
                </div>
            </div>
        </form>
        <br>
        <b-alert v-if="error !== ''" type="danger" :title="error"/>
    </div>
</template>

<script>
import DoorService from '@/services/DoorService';
import bAlert from '@/components/bAlert.vue';
import UserService from '@/services/UserService';

export default {
    name: 'addDoorView',
    components: {
        bAlert,
    },
    data: () => ({
        nome: '',
        id: '',
        utente: '',
        linked: '',
        users: [],
        doors: [],
        error: '',
    }),
    mounted() {
        this.loadUsers();
        this.loadDoors();
    },
    methods: {
        handleSubmit(e) {
            var self = this;
            e.preventDefault();

            var data = {
                id: this.id,
                nome: this.nome,
                utente: this.utente,
                linked: this.linked,
            };

            console.log(this.id);

            DoorService.createDoor(data, this.$store.getters.getJWT, this.$store.getters.getCSRF)
            .then(res => {
                console.log(res);
                self.$router.push('/porte');
            }).catch(err => {
                console.log(err);
                self.error = err.response;
            });
        },
        loadDoors() {
            var self = this;
            DoorService.getDoors(this.$store.getters.getJWT)
            .then(res => {
                self.doors = res.doors.data;
            }).catch(err => {
                self.error = err;
            });
        },
        loadUsers() {
            var self = this;
            UserService.getUsers(this.$store.getters.getJWT)
            .then(res => {
                self.users = res.users.data;
            }).catch(err => {
                self.error = err;
            });
        }
    }
};
</script>