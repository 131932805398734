import axios from 'axios';

const URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_DEV_API_URL

export default {
    getUsers(token) {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/users/all', {headers: {"Authorization": token}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    addUser(data, jwt, csrf) {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/users/add', data, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf,
            }}).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    getUser(token, id) {
        return new Promise((resolve, reject) => {
            axios.get(URL+`/users/get/${id}`, {headers: {'Authorization': token}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    deleteUser(token, id) {
        return new Promise((resolve, reject) => {
            axios.get(URL+`/users/delete/${id}`, {headers: {'Authorization': token}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    }
};