import axios from 'axios';

const URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_DEV_API_URL

export default {
    loadSettings(jwt) {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/settings/get', {headers: {
                'Authorization': jwt,
            }}).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    setAppName(data, jwt, csrf) {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/settings/set-app-name', data, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf
            }})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    uploadLogo(formData, jwt, csrf) {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/settings/upload-logo', formData, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf,
            }})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    }
};