import store from '@/store';
import axios from 'axios';

const URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_DEV_API_URL

export default {
    login: (email,passwd) => {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/auth/authenticate', {email: email, password: passwd})
            .then(res => resolve(res.data))
            .catch(err => reject(err.response));
        });
    },
    verify(token) {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/auth/verify-id', {headers: {'Authorization': token}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    changePasswd(data, jwt, csrf) {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/auth/change-password', data, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf,
            }}).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    logout() {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/auth/logout')
            .then(res => {
                localStorage.removeItem("auth");
                localStorage.removeItem("user");
                return resolve(res.data);
            })
            .catch(err => reject(err));
        });
    },
    getCSRF(jwt) {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/auth/obtain-csrf', {headers: {'Authorization': jwt}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    storeLocal(data) {
        localStorage.setItem("auth", JSON.stringify(data));
    },
    loadAuthData() {
        if(localStorage.getItem("auth")) {
            var data = JSON.parse(localStorage.getItem("auth"));
            store.dispatch("setJWT", data.accessToken);
            store.dispatch("setUserData", data.user);
            return true;
        } else {
            return false;
        }
    },
};