<template>
    <div class="container">
        <h1>Aggiungi utente</h1>
        <form @submit="handleSubmit">
            <div class="row">
                <div class="col">
                    <label>Email</label>
                    <input type="email" v-model="email" class="form-control">
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Nome utente</label>
                        <input type="text" v-model="username" class="form-control">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label>Ruolo</label>
                        <select v-model="role" class="form-select">
                            <option value="">Seleziona</option>
                            <option value="Cliente">Cliente</option>
                            <option value="Admin">Admin</option>
                        </select>
                    </div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">
                            <i class="fa fa-plus"></i>
                            Salva
                        </button>
                    </div>
                    <div class="col">
                        <button @click="$router.push('/utenti')" type="button" class="btn btn-danger">
                            <i class="fa fa-arrow-left"></i>
                            Indietro
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import UserService from '@/services/UserService';

export default {
    name: 'addUserView',
    data: () => ({
        username: '',
        email: '',
        role: '',
    }),
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            var self = this;

            var data = {
                email: this.email,
                username: this.username,
                role: this.role,
            };
            UserService.addUser(data, this.$store.getters.getJWT, this.$store.getters.getCSRF)
            .then(res => {
                console.log(res);
                self.$router.push('/utenti');
            }).catch(err => {
                console.log(err);
            });
        }
    }
};
</script>