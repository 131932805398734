<template>
    <div class="container d-flex justify-content-center align-items-center mt-4">
        <form class="elevation-4 px-4 py-4" @submit="handleSubmit">
            <h1>Admin Panel - Login</h1>
            <br>
            <div class="row">
                <div class="col">
                    <label>Email</label>
                    <input type="email" v-model="email" class="form-control">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <label>Password</label>
                    <div class="input-group">
                        <input :type="showPsw ? 'text' : 'password'" v-model="password" class="form-control">
                        <i :class="showPsw ? 'fa fa-eye-slash input-group-text' : 'fa fa-eye input-group-text'" style="cursor: pointer;" @click="showPsw = !showPsw"></i>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col">
                    <button v-if="loading" class="btn btn-primary">
                        <loadingSpinner color="light" />
                    </button>
                    <button v-else class="btn btn-primary">
                        <i class="fa fa-right-to-bracket"></i>
                        Accedi
                    </button>
                </div>
            </div>
            <br>
            <b-alert v-if="error !== ''" type="danger" :title="error" />
        </form>
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import bAlert from '@/components/bAlert.vue';
import loadingSpinner from '@/components/loadingSpinner.vue';

export default {
    name: 'LoginView',
    components: {
        bAlert,
        loadingSpinner,
    },
    data: () => ({
        email: '',
        password: '',
        error: '',
        loading: false,
        showPsw: false,
    }),
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            var self = this;
            this.loading = true;

            AuthService.login(this.email, this.password)
            .then(res => {
                console.log(res);
                self.$store.dispatch('setJWT', res.accessToken);
                self.$store.dispatch('setCSRF', res.csrfToken);
                self.$store.dispatch('setUserData', res.user);
                AuthService.storeLocal({
                    accessToken: self.$store.getters.getJWT,
                    csrf: self.$store.getters.getCSRF,
                    user: res.user,
                });
                self.$router.push('/');
                self.loading = false;
            }).catch(err => {
                console.log(err);
                self.error = err.data.status;
                self.loading = false;
            });
        }
    }
};
</script>