import axios from 'axios';

const URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_DEV_API_URL

export default {
    getEvents(doorId, jwt) {
        return new Promise((resolve, reject) => {
            axios.get(URL+`/events/get/${doorId}`, {headers: {'Authorization': jwt}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    addEvent(jwt, csrf, data) {
        return new Promise((resolve, reject) => {
            axios.post(URL+'/events/add', data, {headers: {
                'Authorization': jwt,
                'X-CSRF-TOKEN': csrf,
            }}).then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    getEventTypes(jwt) {
        return new Promise((resolve, reject) => {
            axios.get(URL+'/events/types/get', {headers: {'Authorization': jwt}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    },
    deleteEvent(jwt, id) {
        return new Promise((resolve, reject) => {
            axios.get(URL+`/events/delete/${id}`, {headers: {'Authorization': jwt}})
            .then(res => resolve(res.data))
            .catch(err => reject(err));
        });
    }
};