<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4 position-fixed">
        <router-link class="brand-link bg-lightblue" to="/">
            <img v-if="$store.getters.getAppLogo !== ''" class="brand-image img-circle" :src="'/api/download?file='+$store.getters.getAppLogo">
            <span v-if="$store.getters.getAppName !== '' && $store.getters.getAppName !== null " class="brand-text font-weight-light">{{ $store.getters.getAppName }}</span>
            <span class="brand-text font-weight-light" v-else>Admin Dashboard</span>
        </router-link>
        <div class="sidebar">
            <account-panel :account-name="$store.getters.getUserData.name" />
        </div>
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" role="menu" data-widget="treeview">
                <nav-item :active="$route.path === '/'" url="/" icon="fa fa-dashboard" title="Dashboard" />
                <nav-item :active="$route.path === '/porte'" url="/porte" icon="fa fa-camera" title="Visualizza porte" />
                <nav-item :active="$route.path === '/aggiungi-porta'" url="/aggiungi-porta" icon="fa fa-plus" title="Aggiungi porta" />
                <nav-item :active="$route.path === '/utenti'" url="/utenti" icon="fa fa-users" title="Visualizza utenti" />
                <nav-item :active="$route.path === '/aggiungi-utente'" url="/aggiungi-utente" icon="fa fa-user-plus" title="Aggiungi utente" />
                <nav-item :active="$route.path === '/impostazioni'" url="/impostazioni" icon="fa fa-cog" title="Impostazioni" />
            </ul>
        </nav>
    </aside>
</template>

<script>
import accountPanel from './accountPanel.vue';
import navItem from './navItem.vue';

export default {
    name: 'sideBar',
    components: {
        accountPanel,
        navItem,
    }
};
</script>