<template>
    <nav class="main-header navbar navbar-expand navbar-dark bg-lightblue">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" role="button" data-widget="pushmenu"><i class="fa fa-bars"></i></a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a role="button" style="cursor: pointer;" class="nav-link" @click="logout">
                    <i class="fa fa-right-from-bracket"></i>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
    name: 'mainHeader',
    methods: {
        logout() {
            var self = this;

            AuthService.logout()
            .then(res => {
                console.log(res);
                self.$store.dispatch('setJWT', '');
                self.$store.dispatch('setCSRF', '');
                self.$store.dispatch('setUserData', null);
                self.$store.dispatch('setAppName', '');
                self.$store.dispatch('setAppLogo', '');
                self.$router.push('/login');
            }).catch(err => alert(err.message));
        }
    }
};
</script>