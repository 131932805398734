<template>
    <table class="table">
        <thead>
            <tr>
                <th :key="header.text" v-for="header in headers">{{ header.text }}</th>
            </tr>
        </thead>
        <tbody>
            <slot name="body"></slot>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'dataTable',
    props: {
        headers: Array,
    }
};
</script>