<template>
    <i :style="'color: '+color+';'" @click="$emit('click')" :class="icon"></i>
</template>

<script>
export default {
    name: 'iconButton',
    props: {
        icon: String,
        color: String,
    }
};
</script>

<style scoped>
i {
    cursor: pointer;
}
</style>